import axios from "axios";

export const domain = "react-music-express.vercel.app/"; 
console.log(process.env.NODE_ENV);

export const URL = `https://${domain}`; // `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;

export const api = axios.create({
  baseURL: `${URL}`,
  headers: {
    "Content-Type": "application/json",
  },
});
