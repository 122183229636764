import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import { Wrapper } from "./PlayBar.styles";
import {
  AiFillStepBackward,
  AiFillStepForward,
  AiFillCaretRight,
  AiOutlinePause,
  AiOutlineAlignLeft,
  AiOutlineAlignCenter,
  AiOutlineIdcard,
  AiOutlineFire,
  AiOutlineProfile,
  AiOutlinePicLeft,
} from "react-icons/ai";
import { BsMusicNoteList, BsShuffle } from "react-icons/bs";
import { SongContext } from "../context/SongProvider";
import { documentTitle, mediaSession } from "../utils/documentTitle";
import { api } from "../services/api";
import { playModeIcons } from "../assets/icons/playModeIcons";
import { useHistory, useLocation } from "react-router-dom";
import { checkMusicAvailability } from "../utils/checkMusicAvailability";
import { MessageContext } from "../context/MessageProvider";
import { throttle } from "../utils/throttleAndDebunce";
import { getSong, getTrackDetails } from "../api/track";
import { getLyric } from "../api/others";
import MusicPlayPanel from "./MusicPlayPanel";
import { PlayerContext } from "../context/PlayerProvider";

function PlayBar() {
  const localPlayModeIndex = localStorage.getItem("playModeIndex");
  const [isModalActive, setIsModalActive] = useState({
    currentPlaylist: false,
    playPanel: false,
  });

  const history = useHistory();
  const location = useLocation();

  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);
  const playerContext = useContext(PlayerContext);
  if (!songContext || !messageContext || !playerContext) throw new Error();
  const {
    song,
    setSong,
    playList,
    renderList,
    currentList,
    setCurrentList,
    setClickedId,
    isFirstTime,
    setIsFirstTime,
    // setLyric,
    historyList,
    setHistoryList,
    //playCurrentTime,
    //setPlayCurrentTime,
    isDelete,
    setIsDelete,
    clickedSong,
    //isMusicPlaying,
    //setIsMusicPlaying,
    analyticData,
    setAnalyticData,
  } = songContext;
  // console.log("song is", song);

  const { dispatch } = messageContext;

  const {
    lyric,
    setLyric,
    isMusicPlaying,
    setIsMusicPlaying,
    playCurrentTime,
    setPlayCurrentTime,
    playModeIndex,
    setPlayModeIndex,
    progressValue,
    setProgressValue,
    isPlayPanelShowing,
    setIsPlayPanelShowing,
    clickedPlayerControlOption,
    setClickedPlayerControlOption,
    // handleShuffle,
    // handlePreviousSong,
    // handleNextSong,
    playerRef,
  } = playerContext;

  const setTitleAndMediaSession = () => {
    const { name, artists, image, album } = song;
    console.log("song is", song);

    documentTitle(name, artists);
    mediaSession(
      name,
      artists,
      album,
      setIsMusicPlaying,
      handlePreviousSong,
      handleNextSong,
      handleShuffle,
      playModeIndex,
      image + "?param=300y300"
    );
  };

  const fetchSong = async (id: number, index: number) => {
    setClickedId(id); // Used to set playing indicator icon
    try {
      const response = await getSong(id); // api.get(`/song/url?id=${id}`);
      console.log(response, response.data.data[0].url);
      // If url is null, show error msg, and not setting clickedId as indicator
      if (!checkMusicAvailability(response.data.data[0].url, dispatch)) {
        setClickedId(song.id || 0); // Used to remove playing indicator icon or still with current playing song
        return false;
      }

      // Below to update the current list,
      // FIXME: 2021-07-13 21:38:55, atm, song data heavily depends on the current list, which make it quite hard to move a place for current list
      //2021-07-17 12:23:59 fixed with updating the data structure for clickedSong state
      const checkedIndex = currentList.findIndex((item) => item.id === id);

      // if equal to -1, means it's a new song not playing from currentList(must by clicking), other wise, it's a song within currentList either by clicking or auto playing
      if (checkedIndex === -1) {
        setSong({
          ...clickedSong, //FIXME: If a new song(not in the currentList) could be played by not clicking, the issue will arise. Need to make it more robust
          url: response.data.data[0].url,
          index: currentList.length,
        });
        // If the song.id cannot be found in the renderList, then just send another request for this song details
        //FIXME: 2021-12-27 22:44:57 Looks like only want to reduce a fetch request if the song is in the renderList, but it makes the renderList as a global state, which is not good.
        const filterRenderList = renderList.filter((item) => item.id === id);
        if (filterRenderList[0]) {
          setCurrentList((prev) => [...prev, filterRenderList[0]]);
        } else {
          const songDetailsResponse = await getTrackDetails(String(id));
          if (songDetailsResponse.data.songs[0]) {
            setCurrentList((prev) => [
              ...prev,
              songDetailsResponse.data.songs[0],
            ]);
          }
        }
      } else {
        setSong({
          id,
          index: checkedIndex,
          url: response.data.data[0].url,
          name: currentList[checkedIndex].name,
          artists: currentList[checkedIndex].ar
            .map((artist) => artist.name)
            .join(" "),
          artistId: +currentList[checkedIndex].ar[0].id,
          image: currentList[checkedIndex].al.picUrl,
          album: currentList[checkedIndex].al.name,
          mvId: currentList[checkedIndex].mv,
        });
      }

      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const handleNextSong = async (i: number = 1) => {
    // i is used to try to play next song if the current song is not playable
    if (currentList.length) {
      const songId = (currentList[song.index + i] || currentList[i - 1]).id; // i-1 rather than 0 is to avoid the first song's url is null
      const songIndex =
        song.index + i < currentList.length ? song.index + i : i - 1; // Either the last song item or the first item
      (await fetchSong(songId, songIndex)) || handleNextSong(i + 1);
    }
  };

  const handlePreviousSong = async (i: number = 1) => {
    if (currentList.length) {
      (await fetchSong(
        (currentList[song.index - i] || currentList[currentList.length - i]).id, // FIXME: At the second song position,if the first song is null, the second from the last rather than the last song will be played.
        song.index - i < 0 ? currentList.length - i : song.index - i // Either the first song item or the last item
      )) || handlePreviousSong(i + 1);
    }
  };

  const handleShuffle = async (i: number = 1) => {
    const currentListLength = currentList.length;
    if (currentListLength > 2) {
      const randomIndex = Math.floor(Math.random() * currentListLength);
      const songIndex =
        randomIndex === song.index ? song.index + i : randomIndex; // If the randomIndex is equal to current song index, then set randomIndex to song index plus one
      (await fetchSong(
        (currentList[songIndex] || currentList[i - 1]).id,
        songIndex < currentList.length ? songIndex : i - 1 // Either the songIndex item or the first item
      )) || handleShuffle(i + 1);
    } else {
      return handleNextSong();
    }
  };

  const fetchLyric = async (songId: number) => {
    try {
      const response = await getLyric(songId); // api.get(`/lyric?id=${songId}`);
      // console.log(response);
      response.data.lrc && response.data.lrc.lyric
        ? setLyric(response.data.lrc.lyric)
        : setLyric(""); // Sometimes, the response.data.lrc.lyric could have null value
    } catch (error) {
      console.error(error);
      setLyric("");
    }
  };

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.addEventListener("loadedmetadata", () => {
        // console.log(
        //   "duration is",
        //   playerRef.current && playerRef.current.duration
        // );
      });
    }

    // Only autoplay when there is song.url and not the first time to the app (user must first has some interaction)
    if (song.url && !isFirstTime) {
      //Below is related to play
      setIsMusicPlaying(true); // Set icon to play when the song data is not empty
      playerRef.current && playerRef.current.play(); // Play the song when the it is changed and url not empty
      song.id && fetchLyric(song.id); // Fetch lyric when there is an id
      setTitleAndMediaSession();

      // Below is to update analytics data
      // For some time, the song id could be 0 (gift clicking, initial state, etc), the analyticData shouldn't be updated in that case
      if (song.id && song.name && song.artists) {
        const checkAnalyticDataExistIndex = analyticData.findIndex(
          (item) => item.id === song.id
        );
        //console.log("checkAnalyticDataExist", checkAnalyticDataExistIndex)
        if (checkAnalyticDataExistIndex === -1) {
          const { id, name, artists, artistId, image, album, mvId } = song;
          setAnalyticData((prev) => [
            ...prev,
            {
              //...clickedSong, //FIXME: Logic is not correct when handling auto playing
              id,
              name,
              artists,
              artistId,
              image,
              album,
              mvId,
              playCount: 1,
              date: [new Date(Date.now()).toISOString()],
            },
          ]);
        } else {
          setAnalyticData((prev) =>
            prev.map((item, index) =>
              index === checkAnalyticDataExistIndex
                ? {
                    ...item,
                    playCount: item.playCount + 1,
                    date: [...item.date, new Date(Date.now()).toISOString()],
                  }
                : item
            )
          );
        }
      }
    }
  }, [song]);

  useEffect(() => {
    // if (!song) return;
    //console.log(currentList);

    if (
      !currentList[song.index] ||
      historyList.findIndex((item) => item.id === song.id) !== -1
    )
      return;
    setHistoryList((prev) => [...prev, currentList[song.index]]);
  }, [currentList]);

  // Used for currentPlaylist deleting
  useEffect(() => {
    if (isDelete) {
      handleNextSong();
      setIsDelete(false); // Used to make the isDelete work for next time
    }
  }, [isDelete]);

  // Used for currentPlaylist/MusicListDetails playing
  useEffect(() => {
    if (clickedSong.id) {
      fetchSong(clickedSong.id, clickedSong.index);
    }
  }, [clickedSong]);

  useEffect(() => {
    // The reason why not putting the code below within "play-pause" switch case is because isMusicPlaying there indicates previous state, and the new state after setIsMusicPlaying cannot be seen there
    if (playerRef.current) {
      if (isMusicPlaying) {
        if (isFirstTime && song.id) {
          setIsFirstTime(false); // In order to make the above playerRef.current.play() work
          fetchSong(song.id, song.index); // One reason is to avoid 403 error for the song.url stored locally, the other is for the playing song indicator icon,
        }
        playerRef.current.play();
        setTitleAndMediaSession();
      } else {
        playerRef.current.pause();
      }
    }
  }, [isMusicPlaying]);

  // To do autoplay about next songs
  useEffect(() => {
    // console.log("progressValue", progressValue);
    if (progressValue === 1) {
      switch (playModeIndex) {
        case 0:
          handleNextSong();
          break;
        case 1:
          handleShuffle();
          break;
        case 2:
          fetchSong(song.id, song.index);
          break;
        default:
          console.error("Error, no such case");
      }
      // console.log("currentIndex is ", song.index);
      setIsMusicPlaying(false); // Switch to pause icon when the song is finished
    }
  }, [progressValue]);

  useEffect(() => {
    const trigger = async () => {
      if (clickedPlayerControlOption.name === "next") {
        if (playModeIndex === 1) return handleShuffle(); // If the user choose shuffle mode, next song should in shuffle mode
        handleNextSong();
      } else if (clickedPlayerControlOption.name === "previous") {
        handlePreviousSong();
      }
    };
    trigger();
  }, [clickedPlayerControlOption.timestamp]);

  // FIXME: Not sure why throttle not working with setState
  const handleCurrentTime = useCallback(() => {
    throttle(() => {
      if (playerRef.current) {
        console.log("triggered");
        setPlayCurrentTime(playerRef.current.currentTime);
      }
    }, 2000);
  }, []);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    // console.log(e.target);

    const { name } = e.currentTarget.dataset;
    // console.log("tab name is", name, location);

    if (name !== "play-pause") {
      setIsFirstTime(false);
      // FIXME: code below will cause the currentPlaylist not working (because of  "song.url && !isFirstTime" above) when isFirstTime is true.
      // setTimeout(() => {
      //   setIsFirstTime(true); // If there is no interaction after 5 min, set isFirstTime to true in order to trigger fetchSong function and avoid 403 error.
      // }, 5*60 * 1000);
    }
    switch (name) {
      case "play-panel":
        if (location.pathname === "/panel") {
          history.goBack(); // If clicking the same icon the second time, close the modal
        } else {
          history.push({
            pathname: "/panel",
            // search: "?play-panel",
            // state: { background: location },
          });
        }

        break;
      case "trigger-play-panel":
        //setIsPlayPanelShowing(true);
        break;
      case "play-mode":
        setPlayModeIndex((prev) => (prev < 2 ? prev + 1 : 0));
        break;
      case "previous-song":
        if (playModeIndex === 1) return handleShuffle();
        handlePreviousSong();
        break;
      case "play-pause":
        setIsMusicPlaying((prev) => !prev);

        break;
      case "next-song":
        if (playModeIndex === 1) return handleShuffle(); // If the user choose shuffle mode, next song should in shuffle mode
        handleNextSong();
        break;
      case "current-playlist":
        if (location.search === "?current-playlist") {
          history.goBack(); // If clicking the same icon the second time, close the modal
        } else {
          history.push({
            pathname: location.pathname,
            search: "?current-playlist",
            state: { background: location },
          });
        }

        break;
      case "progress-bar":
        // console.log(e.nativeEvent.offsetX); // Not sure why e.offsetX not working
        const percent = e.nativeEvent.offsetX / e.currentTarget.offsetWidth;
        // Add song.url to avoid NaN issue for playerRef.current.duration which could crash the app
        if (song.url && playerRef.current) {
          console.log(
            "duration is",
            playerRef.current.currentTime,
            playerRef.current.duration
          );
          playerRef.current.currentTime = percent * playerRef.current.duration;
          setPlayCurrentTime(playerRef.current.currentTime);
          //progressRef.current.value =playerRef.current.currentTime / playerRef.current.duration
          setProgressValue(
            playerRef.current.currentTime / playerRef.current.duration
          );
        }
        break;
      default:
        console.error("Error, no such case");
    }
  };

  const handleTimeUpdate = () => {
    if (
      playerRef.current &&
      playerRef.current.duration
      //&&progressRef.current
    ) {
      // progressRef.current.value =
      //   playerRef.current.currentTime / playerRef.current.duration;
      setProgressValue(
        playerRef.current.currentTime / playerRef.current.duration
      );
      //handleCurrentTime();
      setPlayCurrentTime(playerRef.current.currentTime);
      //if (progressRef.current.value === 1) {
    }
  };

  const handleAudioEnded = () => {
    console.log("on end is triggered");

    setProgressValue(1);
  };

  const playPanelProps = {
    setIsPlayPanelShowing,
    playModeIndex,
    lyric,
    isMusicPlaying,
    playCurrentTime,
    setPlayCurrentTime,
    setPlayModeIndex,
    handleShuffle,
    handlePreviousSong,
    setIsMusicPlaying,
    handleNextSong,
    playerRef,
    progressValue,
    setProgressValue,
  };

  return (
    <>
      {/* <div style={{ display: isPlayPanelShowing ? "block" : "none" }}>
        <MusicPlayPanel {...playPanelProps} />
      </div> */}

      <Wrapper>
        <div className="music-progress">
          <progress
            // ref={progressRef}
            data-name="progress-bar"
            onClick={handleClick}
            value={progressValue}
            max="1"
          ></progress>
        </div>
        <div className="music-control">
          <div className="left" data-name="play-panel" onClick={handleClick}>
            {song.name ? (
              <>
                <div
                  className="current-music-cover"
                  // data-name="play-panel"
                  // onClick={handleClick}
                >
                  {/* <AiOutlineAlignLeft /> */}
                  {/* <AiOutlineAlignCenter /> */}
                  {/* <AiOutlineIdcard /> */}
                  {/* <AiOutlinePicLeft /> */}
                  <img src={song.image + "?param=60y60"} alt="album cover" />
                </div>
                <div className="music-short-info">
                  <div className="song-name">{song.name}</div>
                  <div className="song-artist"> {song.artists}</div>
                </div>
              </>
            ) : null}
          </div>

          {/* <div className="play-mode" data-name="play-mode" onClick={handleClick}>
          {playModeIcons[playModeIndex]}
        </div> */}
          {/* <div
          className="previous"
          data-name="previous-song"
          onClick={handleClick}
        >
          <AiFillStepBackward />
        </div> */}
          <div className="right">
            <div className="play-control">
              <audio
                ref={playerRef}
                onTimeUpdate={handleTimeUpdate}
                onEnded={handleAudioEnded}
                src={song.url} //"https://cdn.jsdelivr.net/gh/wensuimo/music/%E7%83%AD%E6%B2%B3-live.mp3"
              />
              <div
                className="play-pause"
                data-name="play-pause"
                onClick={handleClick}
              >
                {isMusicPlaying ? <AiOutlinePause /> : <AiFillCaretRight />}
              </div>
            </div>
            {/* <div className="next" data-name="next-song" onClick={handleClick}>
          <AiFillStepForward />
        </div> */}

            <div
              className="play-list"
              data-name="current-playlist"
              onClick={handleClick}
            >
              <BsMusicNoteList />
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default PlayBar;
