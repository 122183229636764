import Vibrant from "node-vibrant";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getAlbumDetails } from "../api/album";
import { getArtistIdentity, getArtistTopPlaylist } from "../api/artist";
import { getPlaylistDetails } from "../api/playlist";
import { getTrackDetails } from "../api/track";
import MainContainer from "../components/layouts/MainContainer";
import MusicListDetails from "../components/MusicListDetails";
import SearchBar from "../components/SearchBar";
import { MessageContext } from "../context/MessageProvider";
import { SongContext } from "../context/SongProvider";

interface LocationType {
  pathname: string;
  search: string;
  state: {
    playlistId: number;
    category: string;
  };
}

export interface Params {
  type: string;
  id: string;
}

export const categoryList = [
  "trends",
  "recommended",
  "hot",
  "favorite",
  "playlist",
];

function PlayList() {
  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);

  if (!songContext) throw new Error();
  const {
    albumInfo,
    setAlbumInfo,
    playList,
    setPlayList,
    renderList,
    setRenderList,
  } = songContext;

  const location: LocationType = useLocation();
  const params = useParams<Params>();
  const playListId = +params.id;

  console.log("playListId", playListId, params);

  const { status, data: playlistInfo, error } = useQuery(
    ["playlistInfo", playListId],
    () => getPlaylistDetails(playListId),
    {
      enabled: categoryList.includes(params.type),
      onSuccess: (data) => {
        const { id, name, description, coverImgUrl } = data.data.playlist;
        setAlbumInfo({ id, name, description, cover: coverImgUrl });
      },
    }
  );

  //console.log("playlistInfo", playlistInfo);
  const trackIds: number[] = playlistInfo?.data.playlist.trackIds.map(
    (item: { id: number }) => item.id
  );
  //console.log(status, location.pathname);
  const { isIdle, data: playlistDetails } = useQuery(
    ["playlistDetails", trackIds],
    () => getTrackDetails(trackIds.join(",")),
    {
      enabled: !!trackIds,
      onSuccess: (data) => {
        setPlayList(data.data.songs);
      },
    }
  );

  //console.log("playlistDetails", playlistDetails);

  const { data: artistPlaylist } = useQuery(
    ["artistPlaylist", playListId],
    () => getArtistTopPlaylist(playListId),
    {
      enabled: params.type === "artists",
      onSuccess: (data) => {
        setPlayList(data.data.songs);
      },
    }
  );

  const { data } = useQuery(
    ["artistInfo", playListId],
    () => getArtistIdentity(playListId),
    {
      enabled: params.type === "artists",
      onSuccess: (data) => {
        const { id, name, briefDesc, cover } = data.data.data.artist;
        setAlbumInfo({ id, name, description: briefDesc, cover });
      },
    }
  );

  const { data: albumDetails } = useQuery(
    ["albumDetails", playListId],
    () => getAlbumDetails(playListId),
    {
      enabled: params.type === "album",
      onSuccess: (data) => {
        //console.log("albumDetails", data);
        const { id, name, picUrl } = data.data.songs[0].al;
        setAlbumInfo({ id, name, description: "", cover: picUrl });
        setPlayList(data.data.songs);
      },
    }
  );

  //console.log("albumInfo", albumInfo);

  // useEffect(() => {
  //   if (
  //     location.pathname.includes("/playlist") ||
  //     location.pathname.includes("/search-result")
  //   ) {
  //     return setRenderList(playList);
  //   }

  //   if (location.pathname === "/favorite") {
  //     return setRenderList(favoriteList);
  //   }

  //   if (location.pathname === "/history") {
  //     return setRenderList(historyList);
  //   }
  // }, [playList, favoriteList, historyList]);

  // console.log("renderList is", renderList);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [playList]);

  useEffect(() => {
    if (albumInfo?.cover) {
      const vibrant = new Vibrant(albumInfo.cover);
      vibrant
        .getPalette()
        .then((palette: any) =>
          setAlbumInfo({ ...albumInfo, color: palette?.DarkMuted?.hex })
        );
    }
  }, [albumInfo?.cover]);

  const musicListDetailsProps = {
    albumInfo,
    renderList: playList,
    //categoryList,
    //params,
  };

  return (
    <MainContainer>
      <MusicListDetails {...musicListDetailsProps} />
    </MainContainer>
  );
}

export default PlayList;
