import React, { useMemo } from "react";
import { useContext } from "react";
import { MessageContext } from "../context/MessageProvider";
import { SongContext } from "../context/SongProvider";
import { Cube, Wrapper } from "./ThreeDCube.styles";
import { RiArrowDownSLine, RiYoutubeLine } from "react-icons/ri";
import { AiOutlineMore } from "react-icons/ai";
import { VscArrowLeft } from "react-icons/vsc";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const colorsDict = {
  white: "235,235,235",
  golden: "246, 216, 96",
  orange: "255,127,63",
  green: "149,205,65",
  blue: "28,109,208",
};

function ThreeDTube() {
  const { t } = useTranslation();
  const history = useHistory();

  const [isShowing, setIsShowing] = useState<{ [key: string]: boolean }>({
    data: true,
  });
  const [pickedColor, setPickedColor] = useState(colorsDict.white);
  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);

  if (!songContext || !messageContext) throw new Error();

  const { analyticData } = songContext;

  const { dispatch } = messageContext;

  const maxPlayCount = useMemo(() => {
    return Math.max(...analyticData.map((item) => item.playCount));
  }, [analyticData]);
  //console.log("maxPlayCount", maxPlayCount);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    dispatch({ type: "under-development" });
    setTimeout(() => {
      dispatch({ type: "none" });
    }, 3000);

    const { action } = e.currentTarget.dataset;
    console.log(action);

    switch (action) {
      case "backward":
        history.goBack();
        break;
      case "settings":
        break;
      case "data":
        setIsShowing((prev) => ({ [action]: !prev[action] }));
        break;
      case "analytics":
        setIsShowing((prev) => ({ [action]: !prev[action] }));
        break;
      case "music":
        setIsShowing((prev) => ({ [action]: !prev[action] }));
        break;
      case "nrh":
        setIsShowing((prev) => ({ [action]: !prev[action] }));
        break;
      default:
        console.error("Error, no such action case " + action);
    }
  };

  const handleColorPick = (e: React.MouseEvent<HTMLElement>) => {
    const { color } = e.currentTarget.dataset;
    console.log(color);
    if (color) setPickedColor(color);
  };

  return (
    <Wrapper>
      <div className="header">
        <div className="back-icon" data-action="backward" onClick={handleClick}>
          <VscArrowLeft />
        </div>
        <div className="settings" data-action="settings" onClick={handleClick}>
          <AiOutlineMore />
        </div>
      </div>
      <Cube>
        <div className="td-cube">
          <ul>
            <li className="front" data-action="data" onClick={handleClick}>
              Data
            </li>
            <li className="back" data-action="data" onClick={handleClick}>
              数据
            </li>
            <li className="top" data-action="nrh" onClick={handleClick}>
              Nrh
            </li>
            <li
              className="bottom"
              data-action="analytics"
              onClick={handleClick}
            >
              Stats
            </li>
            <li className="left" data-action="analytics" onClick={handleClick}>
              分析
            </li>
            <li className="right" data-action="music" onClick={handleClick}>
              音乐
            </li>
          </ul>
        </div>
      </Cube>
      <div className="data-container">
        {isShowing["data"] ? (
          <>
            <div className="title">
              <h2>{t("analytics.ranking-title")}</h2>
              <div className="color-picker-container">
                {Object.values(colorsDict).map((color, index) => (
                  <span
                    key={index}
                    className="color-picker"
                    style={{ background: `rgb(${color})` }}
                    data-color={color}
                    onClick={handleColorPick}
                  >
                    {pickedColor === color ? "√" : null}
                  </span>
                ))}
              </div>
            </div>
            <ul>
              {analyticData
                .sort((a, b) => b.playCount - a.playCount)
                // .slice(0, 10)
                .map((item, index) => (
                  <li
                    key={index}
                    className="list-item"
                    style={{
                      backgroundImage: `linear-gradient(90deg, rgba( ${pickedColor} , ${
                        item.playCount / maxPlayCount
                      }) ${
                        (item.playCount / maxPlayCount) * 90
                      }%, transparent)`,
                    }}
                  >
                    <div className="album-cover">
                      <img
                        src={item.image + "?param=100y100"}
                        alt={item.album}
                      />
                    </div>
                    <div className="song-info">
                      <div className="song-name">{item.name}</div>
                      <div className="song-artist">{item.artists}</div>
                    </div>
                    <div className="play-count">{item.playCount}</div>
                  </li>
                ))}
            </ul>
          </>
        ) : null}
      </div>
    </Wrapper>
  );
}

export default ThreeDTube;
