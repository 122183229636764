import React, { useState, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { BsThreeDotsVertical, BsX, BsGift } from "react-icons/bs";
import { RiBarChartFill, RiBarChartLine } from "react-icons/ri";
import { AiOutlineFolderAdd, AiOutlineDelete } from "react-icons/ai";
import { SongContext } from "../context/SongProvider";
import { api } from "../services/api";
import { EmptyStatus, Wrapper } from "./CurrentPlayList.styles";
import { MessageContext } from "../context/MessageProvider";
import { useHistory, useLocation } from "react-router-dom";
import { checkMusicAvailability } from "../utils/checkMusicAvailability";
import { useTranslation } from "react-i18next";

let giftId = 38592976; // Dream it possible //29307037; //what are you waiting for //5139948; // Viva la vida
// 1350803943; // Where is all begins  2022-03-03 14:49:28 cannot be played anymore in neteasy
let timeoutId = 0; // Setting here is to avoid the impact caused by rerender

function CurrentPlayList() {
  const { t } = useTranslation();
  const [isGiftClicked, setIsGiftClicked] = useState(0); // Not using boolean is because the type of key predefined by ts is number, string or null

  const history = useHistory();
  const location = useLocation();
  const songContext = useContext(SongContext);
  const messageContext = useContext(MessageContext);

  if (!songContext || !messageContext) throw new Error();
  const {
    currentList,
    setCurrentList,
    song,
    setSong,
    clickedId,
    setClickedId,
    favoriteList,
    setFavoriteList,
    setIsDelete,
    setClickedSong,
  } = songContext;

  const { dispatch } = messageContext;

  //console.log("currentList is", currentList);
  // const songRequest = async (id: number, index: number, artistid: number) => {
  //   setClickedId(+id);
  //   const response = await api.get(`/song/url?id=${id}`);
  //   console.log(response, response.data.data[0].url);
  //   // If url is null, show error msg, and not setting clickedId as indicator
  //   if (
  //     !checkMusicAvailability(response.data.data[0].url, dispatch, timeoutId)
  //   ) {
  //     return setClickedId(0);
  //   }
  //   setSong({
  //     id,
  //     index,
  //     url: response.data.data[0].url,
  //     name: currentList[index].name,
  //     artists: currentList[index].ar.map((artist) => artist.name).join(" "),
  //     artistId: artistid,
  //     image: currentList[index].al.picUrl,
  //   });
  // };

  const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    console.log(e.currentTarget.dataset);

    const {
      action,
      id,
      index,
      name,
      artists,
      artistid,
      image,
      album,
      mvid,
    } = e.currentTarget.dataset;
    // console.log(action, id, index);
    switch (action) {
      case "play":
        if (
          id &&
          index &&
          name &&
          artists &&
          artistid &&
          image &&
          album &&
          mvid
        ) {
          // songRequest(+id, +index, +artistid);
          setClickedSong({
            id: +id,
            index: +index,
            name,
            artists,
            artistId: +artistid,
            image,
            album,
            mvId: +mvid,
          });
        }
        break;
      case "delete":
        if (id) {
          if (+id === song.id) {
            //Only trigger handleNextSong if deleting current playing song
            setIsDelete(true);
          }
          song.index--; // FIXME: To avoid the exceed the currentList index issue when handleNextSong
          setCurrentList((prev) => prev.filter((item) => item.id !== +id));
        }
        break;
      case "gift-fun":
        history.push(`/panel/${giftId}`);
        // setIsGiftClicked(1);
        // const response = await api.get(`/song/url?id=${giftId}`);
        // setSong({
        //   id: +giftId,
        //   index: 0,
        //   url: response.data.data[0].url,
        //   name: "",
        //   artists: "",
        //   artistId: 0,
        //   image: "",
        //   album: "",
        //   mvId: 0,
        // });
        // giftId = Math.floor(100000 + Math.random() * 900000);
        break;
      case "favorite-all":
        if (currentList.length) {
          const filterDuplicates = currentList.filter(
            (item) =>
              favoriteList.findIndex((listItem) => listItem.id === item.id) ===
              -1
          );
          setFavoriteList((prev) => [...prev, ...filterDuplicates]);
          dispatch({ type: "add-fav" });
          setTimeout(() => {
            dispatch({ type: "none" });
          }, 3000);
        }
        break;
      case "delete-all":
        // setCurrentList([]);
        history.push({
          pathname: location.pathname,
          search: "?delete-all",
          state: { background: location, originPage: "currentList" },
        });
        break;
      case "cancel":
        history.goBack();
        break;
      default:
        console.error("Error, no such case");
    }
  };

  // console.log(isGiftClicked);

  const handleDragEnd = (result: any) => {
    console.log(result);

    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const dragItem = currentList[source.index];
    console.log(dragItem);

    // FIXME: Seems mutate the original data
    currentList.splice(source.index, 1);
    currentList.splice(destination.index, 0, dragItem);

    setCurrentList(currentList);

    // A workaround which is used to simulate clicking the current playing song to make the new index work, so that nextSong function could work well
    // setClickedSong({
    //   id: song.id,
    //   index: currentList.findIndex((item) => item.id === song.id),
    // });
  };

  //console.log("currentlist", currentList);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Wrapper>
        <div className="title-area">
          <div className="title">{t("current-playlist.title")} </div>
          <div
            className="favorite"
            data-action="favorite-all"
            onClick={handleClick}
          >
            <AiOutlineFolderAdd />

            <span>{t("current-playlist.fav-all")}</span>
          </div>
          <div
            className="delete"
            data-action="delete-all"
            onClick={handleClick}
          >
            <AiOutlineDelete />
          </div>
        </div>
        <div>
          <Droppable droppableId="droppable">
            {(provided) => (
              <ul ref={provided.innerRef} {...provided.droppableProps}>
                {currentList.length ? (
                  currentList.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <div
                            className="song-item"
                            data-action="play"
                            data-id={item.id}
                            data-index={index}
                            data-name={item.name}
                            data-artists={item.ar
                              .map((artist) => artist.name)
                              .join(" ")}
                            data-artistid={item.ar[0].id}
                            data-image={item.al.picUrl}
                            data-album={item.al.name}
                            data-mvid={item.mv}
                            onClick={handleClick}
                          >
                            <div className="song-index">
                              {+clickedId === item.id ? (
                                <span className="current-indicator-icon">
                                  <RiBarChartLine />
                                </span>
                              ) : (
                                index + 1
                              )}
                            </div>
                            <div className="song-details">
                              <div className="song-title">{item.name}</div>
                              <div className="artist-name">
                                {item.ar.map((artist, index) => (
                                  <span key={index}>{artist.name} </span>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div
                            className="delete-song"
                            data-action="delete"
                            data-id={item.id}
                            data-index={index}
                            data-artistid={item.ar[0].id}
                            onClick={handleClick}
                          >
                            <BsX />
                          </div>
                          {/* <span>
                  <BsThreeDotsVertical />
                </span> */}
                        </li>
                      )}
                    </Draggable>
                  ))
                ) : (
                  <EmptyStatus isClicked={!!isGiftClicked}>
                    <div
                      data-action="gift-fun"
                      className="gift-icon"
                      onClick={handleClick}
                      // onAnimationEnd={() => setIsGiftClicked(0)}
                      // key={isGiftClicked}
                    >
                      <BsGift />
                    </div>
                    <p>{t("current-playlist.no-record")} </p>
                  </EmptyStatus>
                )}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </div>
        <div className="cancel" data-action="cancel" onClick={handleClick}>
          <div className="cancel-inner">{t("action-panel.cancel")}</div>
        </div>
      </Wrapper>
    </DragDropContext>
  );
}

export default CurrentPlayList;
